import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"

import { Container, makeStyles } from "@material-ui/core"

import SEO from "../components/seo"
import BookList from "../components/book-list"
import PageTitle from "../components/page-title"
import Breadcrumbs from "../components/breadcrumbs"

// import CategoryBg from "../../static/images/mainBg.jpg"
import BooksImage from "../../static/images/publishers/books.png"

const windowGlobal = typeof window !== "undefined" && window
const documentGlobal = typeof document !== `undefined` && document

const useStyles = makeStyles(theme => ({
  fullWidth: {
    paddingLeft: "initial",
    paddingRight: "initial",
    position: "relative",
    maxWidth: "100%",
  },
  BooksBg: {
    // backgroundImage: `url(${CategoryBg})`,
    backgroundColor: "#fff",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    margin: 0,
  },
}))

const Books = ({ data, pageContext }) => {
  const classes = useStyles()
  const [t, i18n] = useTranslation()

  const { pLang } = pageContext

  useEffect(() => {
    i18n.on("languageChanged", () => {
      windowGlobal.history.pushState(pLang, "books", `/${i18n.language}/books`)
      if (windowGlobal.history.pushState) {
        windowGlobal.history.pushState(
          pLang,
          "books",
          `/${i18n.language}/books`
        )
      } else {
        documentGlobal.location.href = `/${i18n.language}/books`
      }
    })
  }, [i18n, pLang])

  const breadcrumbs = [
    {
      text: t("book.books"),
    },
  ]

  const books = []
  for (let book of data.allStrapiBooks.nodes) {
    let objCopy = Object.assign({}, book)
    books.push(objCopy)
  }

  return (
    <>
      <SEO title={t("book.books")} lang={i18n.language} />
      <Breadcrumbs links={breadcrumbs} />
      <PageTitle title={t("book.books")} image={BooksImage} />
      <Container className={`${classes.fullWidth} ${classes.BooksBg}`}>
        <BookList books={books} />
      </Container>
    </>
  )
}
export default Books

export const query = graphql`
  query Books {
    allStrapiBooks {
      nodes {
        id
        ISBN
        title
        title_ar
        title_tr
        rating
        strapiId
        quantity
        image {
          publicURL
         childImageSharp {
           fixed(width: 169, height: 244) {
             ...GatsbyImageSharpFixed
           }
         }
        }
        book_items {
          id
          price
          title
          type
        }
      }
    }

    allStrapiPublishers {
      nodes {
        id
        name
        name_ar
        name_tr
        strapiId
        country
        image {
          publicURL
          childImageSharp {
            fixed(width: 169, height: 244) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`
